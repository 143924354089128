import {useContext, useEffect, useRef, useState} from "react";
import InputMask from "react-input-mask";
import {useHistory, generatePath} from 'react-router-dom';
import {BottomSheet} from "react-spring-bottom-sheet";

import {AppContext} from "../../store/context";
import {STATIC_HOST_PRODUCT} from "../../const";
import {classNames, truncateText, unmaskDigits} from "../../helper";
import {AgreementCheckbox, Checkbox} from "../common/Form";
import {publicRoutes} from "../../routing";

import error from '../../static/icons/cart/error.svg'
import plus from '../../static/icons/cart/plus.svg'
import minus from '../../static/icons/cart/minus.svg'
import close from '../../static/icons/cart/close.svg'
import persons from '../../static/icons/cart/persons.svg'
import clear from '../../static/icons/cart/cross.svg'
import redclock from '../../static/icons/cart/red-clock.svg'
import cartEmpty from '../../static/icons/cart/cart-empty.svg'
import cross from "../../static/icons/cross.svg";
import personsMob from '../../static/icons/cart/persons-mob.svg'
import titleShevron from '../../static/icons/cart/title-shevron.svg'

import "../../static/css/react-calendar.css"
import styles from './Cart.module.css'
import {CalendarSelector, formatDate, formatInterval} from "../common/Calendar";


export const ResponsiveCartModal = () => {
    const {context} = useContext(AppContext);

    const onDismiss = () => {
        context.modals.cart.dispatch({type: 'close'});
    };

    const [open, setOpen] = useState(false);
    const [display, setDisplay] = useState(false);

    const isOpen = context.modals.cart.state.open

    useEffect(() => {
        if (isOpen === true) {
            setDisplay(isOpen);
            setTimeout(() => {
                setOpen(isOpen)
            }, 10)
        } else {
            setOpen(isOpen);
            setTimeout(() => {
                setDisplay(isOpen)
            }, 400)
        }
    }, [isOpen])

    return context.settings.isMobile
        ? <BottomSheet
            blocking={false}
            header={<div className={styles.bsClearfix}/>}
            open={context.modals.cart.state.open}
            onDismiss={onDismiss}
            snapPoints={({maxHeight}) => maxHeight - 100}>
            <CartModal/>
        </BottomSheet>
        : <div className={classNames(styles.modal, (open ? styles.modalOpen : ''))}
               style={{display: display ? "flex" : "none"}}>
            <div className={styles.cross} onClick={() => onDismiss()}>
                <img src={cross} alt=""/>
            </div>
            <CartModal/>
        </div>
}

export const CartModal = () => {
    const history = useHistory();
    const {context} = useContext(AppContext);

    const [deliveryAddress, setDeliveryAddress] = useState();

    const addressInput = useRef();

    const today = new Date();
    const maxDate = new Date();

    maxDate.setDate(today.getDate() + 14);

    const [showDatepicker, setShowDatepicker] = useState(false);
    const [promoError, setPromoError] = useState(null);
    const [promoValid, setPromoValid] = useState(context.cart.state.promocode?.promocode !== undefined ? true : null);
    const [promoApply, setPromoApply] = useState(false);
    const [errors, setErrors] = useState(null);
    const [unavailable, setUnavailable] = useState(null);
    const [showSMS, setShowSMS] = useState(false);
    const [inProgress, setProgress] = useState(false);
    const [isMoneyWithoutChange, setIsMoneyWithoutChange] = useState(false);
    const [isCheckLocalData, setIsCheckLocalData] = useState(true);
    const [data, setData] = useState({
        name: context.customer.state.customer.name,
        phone: context.customer.state.customer.phone,

        flat: null,
        floor: null,
        porch: null,
        intercom: null,

        promocode: context.cart.state.promocode?.promocode || null,

        customTime: false,
        date: today,
        time: null,

        persons: null,
        comment: null,
        payment: null,
        change: null,

        withoutCall: context.customer.state.customer.phone !== null,
        agreement: false,
    });

    useEffect(() => {
        if (context.cart.state.delivery.address) {
            setDeliveryAddress(truncateText(context.cart.state.delivery.address, addressInput.current?.clientWidth - 150))
        }
    }, [context.cart.state.delivery.address]);

    useEffect(() => {
        if (context.customer.state.isAuth) {
            setData({
                ...data,
                name: context.customer.state.customer.name,
                phone: context.customer.state.customer.phone
            })
        }
    }, [context.customer.state.isAuth]);

    useEffect(() => {
        if (data.promocode !== null) {
            localStorage.setItem('promocode', data.promocode);
        }

        if (data.name !== null) {
            localStorage.setItem('customerName', data.name);
        }

        if (data.phone !== null) {
            localStorage.setItem('customerPhone', data.phone);
        }

        if (data.withoutCall !== null) {
            localStorage.setItem('customerWithoutCall', data.withoutCall);
        }

        if (data.flat !== null) {
            localStorage.setItem('customerAddressFlat', data.flat);
        }

        if (data.floor !== null) {
            localStorage.setItem('customerAddressFloor', data.floor);
        }

        if (data.porch !== null) {
            localStorage.setItem('customerAddressPorch', data.porch);
        }

        if (data.intercom !== null) {
            localStorage.setItem('customerAddressIntercom', data.intercom);
        }

        if (data.payment !== null) {
            localStorage.setItem('payment', data.payment);
        }

        if (data.persons !== null) {
            localStorage.setItem('persons', data.persons);
        }
    }, [data]);

    useEffect(() => {
        if (isCheckLocalData) {
            let localData = {};
            const localPromocode = localStorage.getItem('promocode');
            const localCustomerName = localStorage.getItem('customerName');
            const localCustomerPhone = localStorage.getItem('customerPhone');
            const localCustomerWithoutCall = localStorage.getItem('customerWithoutCall');
            const localCustomerAddressFlat = localStorage.getItem('customerAddressFlat');
            const localCustomerAddressFloor = localStorage.getItem('customerAddressFloor');
            const localCustomerAddressPorch = localStorage.getItem('customerAddressPorch');
            const localCustomerAddressIntercom = localStorage.getItem('customerAddressIntercom');
            const localPayment = localStorage.getItem('payment');
            const localPersons = localStorage.getItem('persons');

            if (!context.cart.state.promocode && data.promocode === null && localPromocode) {
                localData = {
                    ...localData,
                    promocode: localPromocode
                }
            }

            if (!context.customer.state.customer.name && data.name === null && localPromocode) {
                localData = {
                    ...localData,
                    name: localCustomerName
                }
            }

            if (!context.customer.state.customer.name && data.name === null && localCustomerName) {
                localData = {
                    ...localData,
                    name: localCustomerName
                }
            }

            if (!context.customer.state.customer.phone && data.phone === null && localCustomerPhone) {
                localData = {
                    ...localData,
                    phone: localCustomerPhone
                }
            }

            if (!context.customer.state.customer.phone && data.withoutCall === null && localCustomerWithoutCall) {
                localData = {
                    ...localData,
                    withoutCall: localCustomerWithoutCall
                }
            }

            if (data.flat === null && localCustomerAddressFlat) {
                localData = {
                    ...localData,
                    flat: localCustomerAddressFlat
                }
            }

            if (data.floor === null && localCustomerAddressFloor) {
                localData = {
                    ...localData,
                    floor: localCustomerAddressFloor
                }
            }

            if (data.porch === null && localCustomerAddressPorch) {
                localData = {
                    ...localData,
                    porch: localCustomerAddressPorch
                }
            }

            if (data.intercom === null && localCustomerAddressIntercom) {
                localData = {
                    ...localData,
                    intercom: localCustomerAddressIntercom
                }
            }

            if (data.payment === null && localPayment) {
                localData = {
                    ...localData,
                    payment: localPayment
                }
            }

            if (data.persons === null && localPersons) {
                localData = {
                    ...localData,
                    persons: localPersons
                }
            }

            if (Object.keys(localData).length !== 0) {
                setData({
                    ...data,
                    ...localData
                })
            }

            setIsCheckLocalData(false);
        }
    }, [context.cart.state.promocode, context.customer.state.customer.name, context.customer.state.customer.phone, data, isCheckLocalData]);



    useEffect(() => {
        // Check min sum
        if (context.cart.state.delivery.type === 'delivery' && context.cart.state.sum < 1000) {
            setErrors("Минимальная сумма заказа 1000 ₽")
            return
        }

        if (unavailable !== null) {
            const items = Object.values(context.cart.state.products).filter(el => {
                return unavailable.has(el.id)
            })

            if (items.length !== 0) {
                setErrors('Некоторые блюда недоступны к заказу.')
                return
            }
        }


        setUnavailable(null)
        setErrors(null)
    }, [context.cart.state.total, context.cart.state.delivery.type])

    const changeDeliveryType = () => {
        context.modals.address.dispatch({
            type: "open",
            payload: {
                operation: "changeDeliveryType",
                callback: () => {
                    context.modals.cart.dispatch({type: "open"})
                }
            }
        })

        context.modals.cart.dispatch({type: "close"})
    }

    const changeAddress = () => {
        context.modals.address.dispatch({
            type: "open",
            payload: {
                operation: "changeAddress",
                callback: () => {
                    context.modals.cart.dispatch({type: "open"})
                }
            }
        })

        context.modals.cart.dispatch({type: "close"})
    }
    const clearPromocode = () => {
        if (inProgress) return
        setProgress(true)

        context.processor.cart
            .clearPromocode()
            .then((response) => {
                setPromoValid(null)
                setPromoError(false)
                context.cart.dispatch({type: 'setCart', payload: response.cart})
            })
            .catch(err => context.toast.error(err.message))
            .finally(() => {
                setProgress(false)
            })

    }
    const applyPromocode = () => {
        if (inProgress) return
        setProgress(true)

        const phoneUnmasked = unmaskDigits(data.phone)

        context.processor.cart
            .applyPromocode({code: data.promocode, phone: phoneUnmasked})
            .then((response) => {
                setPromoValid(true)
                setPromoApply(true)
                setTimeout(() => {
                    setPromoApply(false)
                }, 2000)
                context.cart.dispatch({type: 'setCart', payload: response.cart})
            })
            .finally(() => {
                setProgress(false)
            })
            .catch(err => {
                setPromoError(err.message)
                setPromoValid(false)
                setTimeout(() => {
                    setPromoError(null)
                }, 2000)
            })
    }

    const checkout = () => {
        if (context.cart.state.total <= 0) {
            context.toast.error("Корзина пуста")
            return
        }

        if (errors) {
            context.toast.error(errors)
            return
        }

        if (!data.agreement) {
            context.toast.error('Для оформления заказа требуется согласие с Политикой обработки персональных данных и Соглашением об условиями доставки ');
            return;
        }

        const phoneUnmasked = unmaskDigits(data.phone)
        if (phoneUnmasked.length < 11) {
            context.toast.error('Пожалуйста, проверьте корректность введенного номера телефона');
            return;
        }

        if (!data.name || data.name.length < 1) {
            context.toast.error('Пожалуйста, проверьте корректность введенного имени');
            return;
        }

        if (!data.payment) {
            context.toast.error('Пожалуйста, выберите тип оплаты');
            return;
        }

        const request = {
            customer: {
                name: data.name,
                phone: phoneUnmasked,
            },
            persons: data.persons !== null ? data.persons : 0,
            comment: data.comment,
            payment: data.payment,
            withoutCall: data.withoutCall,
        }

        if (data.payment === 'cash') {
            request.change = isMoneyWithoutChange ? 'Без сдачи' : data.change || 'Не указано';
        }

        if (context.cart.state.delivery.type === 'delivery') {
            request.delivery = {
                flat: data.flat,
                floor: data.floor,
                porch: data.porch,
                intercom: data.intercom,
            }
        }

        if (data.customTime) {
            const timeUnmasked = unmaskDigits(data.time)
            const dateFormatted = `${data.date.getFullYear()}-${data.date.getMonth() + 1}-${data.date.getDate()}`
            if (timeUnmasked.length < 4) {
                context.toast.error('Пожалуйста, проверьте корректность времени доставки');
                return;
            }

            request.customTime = {
                date: dateFormatted,
                time: timeUnmasked
            }
        }

        if (inProgress) return
        setProgress(true)

        context.processor.cart.checkout(request)
            .then(res => {
                context.cart.dispatch({type: 'setCart', payload: res.cart});
                localStorage.removeItem('promocode');

                const cartProducts = Object.values(res.order.items).map((product) => {
                    return {
                        'id': product.id,
                        'name' : product.name,
                        'price': product.price,
                        'category': product.category,
                        'quantity': product.quantity,
                    };
                });

                window.dataLayer.push({
                    'ecommerce': {
                        'currencyCode': 'RUB',
                        'purchase': {
                            'actionField': {
                                'id' : res.order.id,
                                'coupon': res.cart.promocode ? res.cart.promocode : '',
                            },
                            'products': cartProducts
                        }
                    }
                });

                if (res.hasOwnProperty('paymentUrl')) {
                    setTimeout(() => {
                        return window.location.assign(res.paymentUrl);
                    }, 200);
                    return
                }

                context.modals.cart.dispatch({type: "close"})
                history.push(generatePath(publicRoutes.order.path, {hash: res.hash}))
            })
            .finally(() => setProgress(false))
            .catch(err => {
                if (err.hasOwnProperty('data')) {
                    if (err.data?.type === 'check_cart') {
                        setUnavailable(new Set(Object.values(err.data?.unavailable)))
                        setErrors("Некоторые блюда недоступны к заказу.")
                    }
                }

                context.toast.error(err.message)
            })
    }
    const plusProduct = (product) => {
        context.processor.cart
            .addProduct({uid: product.uid})
            .then((response) => context.cart.dispatch({type: 'setCart', payload: response.cart}))
            .catch(err => {
                if (err.code === 401) {
                    window.location.reload()
                    return
                }

                if (err.code === 400) {
                    context.toast.error(err.message)
                }
            });
    }
    const minusProduct = (product) => {
        context.processor.cart
            .removeProduct({uid: product.uid})
            .then((response) => context.cart.dispatch({type: 'setCart', payload: response.cart}))
            .catch(err => {
                if (err.code === 401) {
                    window.location.reload()
                    return
                }

                if (err.code === 400) {
                    context.toast.error(err.message)
                }
            });
    }
    const clearProduct = (product) => {
        context.processor.cart
            .clearProduct({uid: product.uid})
            .then((response) => context.cart.dispatch({type: 'setCart', payload: response.cart}))
            .catch(err => {
                if (err.code === 401) {
                    window.location.reload()
                    return
                }

                if (err.code === 400) {
                    context.toast.error(err.message)
                }
            });
    }

    const sendOTP = () => {
        if (showSMS || data.withoutCall) {
            setShowSMS(false)
            return;
        }

        const phoneUnmasked = unmaskDigits(data.phone)
        if (phoneUnmasked.length < 11) {
            context.toast.error('Пожалуйста, проверьте корректность введенного номера телефона');
            return;
        }

        if (inProgress) return
        setProgress(true)

        context.processor.cart.sendOtp(phoneUnmasked)
            .then(res => setShowSMS(true))
            .finally(() => setProgress(false))
            .catch(err => context.toast.error(err.message))
    }

    const verifyOTP = (code) => {
        const codeUnmasked = unmaskDigits(code)
        if (codeUnmasked.length < 4) {
            return;
        }

        const phoneUnmasked = unmaskDigits(data.phone)
        if (phoneUnmasked.length < 11) {
            context.toast.error('Пожалуйста, проверьте корректность введенного номера телефона');
            return;
        }

        if (inProgress) return
        setProgress(true)

        context.processor.cart.verifyOtp(phoneUnmasked, codeUnmasked)
            .then(res => {
                setShowSMS(false)
                setData({...data, withoutCall: true})
            })
            .finally(() => setProgress(false))
            .catch(err => context.toast.error(err.message))
    }

    const _mobileProduct = (el) => {
        return <div
            className={classNames(styles.product, unavailable !== null && unavailable.has(el.id) ? styles.unavailable : '')}>
            <div className={styles.productImage}>
                <img src={STATIC_HOST_PRODUCT + el.image} alt=""/>
            </div>
            <div className={styles.productName}>
                <div className={styles.productTitle}>{el.name}</div>
                <div className={styles.productClear}>
                    <img onClick={() => clearProduct(el)} src={clear} alt=""/>
                </div>
            </div>
            <div className={styles.productSubtitle}>
                {el.option ? <div className={styles.productOption}>{el.option.name}</div> : null}
                <div className={styles.productWeight}>{el.weight}</div>
            </div>
            <div className={styles.productNumbers}>
                {unavailable !== null && unavailable.has(el.id)
                    ? <div className={styles.unavailableTitle}>недоступно</div>
                    : <div className={styles.counter}>
                        <img onClick={() => minusProduct(el)} src={minus} alt=""/>
                        <div className={styles.counterCount}>{el.quantity}</div>
                        <img onClick={() => plusProduct(el)} src={plus} alt=""/>
                    </div>}
                <div className={styles.productSum}>{el.quantity * (el.option?.price ? el.option.price + el.price : el.price)} ₽</div>
            </div>
        </div>
    }

    const _desktopProduct = (el) => {
        return <div
            className={classNames(styles.product, unavailable !== null && unavailable.has(el.id) ? styles.unavailable : '')}>
            <div className={styles.productImage}>
                <img src={STATIC_HOST_PRODUCT + el.image} alt=""/>
            </div>
            <div className={styles.productName}>
                <div className={styles.productTitle}>{el.name}</div>
                <div className={styles.productSubtitle}>
                    {el.option ? <div className={styles.productOption}>{el.option.name}</div> : null}
                    <div className={styles.productWeight}>{el.weight}</div>
                </div>
            </div>
            {unavailable !== null && unavailable.has(el.id)
                ? <div className={styles.unavailableTitle}>недоступно</div>
                : el.price !== 0 ? <div className={styles.counter}>
                    <img onClick={() => minusProduct(el)} src={minus} alt=""/>
                    <div className={styles.counterCount}>{el.quantity}</div>
                    <img onClick={() => plusProduct(el)} src={plus} alt=""/>
                </div> : <div></div>}
            <div className={styles.productSum}>{el.quantity * (el.option?.price ? el.option.price + el.price : el.price)} ₽</div>
            {el.price !== 0 ? <div className={styles.productClear}>
                <img onClick={() => clearProduct(el)} src={clear} alt=""/>
            </div> : <div></div>}
        </div>
    }

    const _deliveryPrice = () => {
        const paidDelivery = context.cart.state.delivery.zone.price > 0 &&
            context.cart.state.sum < context.cart.state.delivery.zone.freeFrom;

        const diff = context.cart.state.delivery.zone.freeFrom - context.cart.state.sum

        const paidOnly = context.cart.state.delivery.zone.paidOnly;


        if (paidOnly) {
            return <div className={styles.heading}>
                <span>доставка</span>
                <span>{context.cart.state.delivery.zone.price} ₽</span>
            </div>
        }

        return <>
            <div className={styles.heading}>
                <span>доставка</span>
                <span>{paidDelivery ? `${context.cart.state.delivery.zone.price} ₽` : 'бесплатно'}</span>
            </div>
            {paidDelivery ?
                <div className={styles.deliveryText}>
                    Закажите ещё на <strong>{diff} ₽</strong> для бесплатной доставки
                </div> : null}
        </>
    }
    const _cartSum = () => {

        if (context.cart.state.discount > 0) {
            return <>
                <div className={styles.sumDiscount}>
                    {context.cart.state.total + context.cart.state.discount}
                </div>
                <div className={styles.sumActual}>
                    {context.cart.state.total} ₽
                </div>
            </>;
        }


        // if (context.cart.state.delivery.type === "pickup" && data.payment === "cash") {
        //     return <>
        //         <div className={styles.sumDiscount}>
        //             {context.cart.state.total}
        //         </div>
        //         <div className={styles.sumActual}>
        //             {Math.round(context.cart.state.total - (context.cart.state.total * 0.15))} ₽
        //         </div>
        //     </>;
        // }

        return <div className={styles.sumActual}>{context.cart.state.total} ₽</div>;
    }

    const _deliveryIntervalString = () => {
        const [from] = formatInterval(data.time, context.cart.state.delivery.type === "delivery")

        return context.cart.state.delivery.type === "delivery"
            ? `Привезем к ${from} (ориентир +-10 минут)`
            : `Заказ будет готов к ${from}`
    }

    const setCustomTime = (date, time) => {
        setShowDatepicker(false)

        setData({...data, date: date, time: time, customTime: true})
    }

    const handleMoneyWithoutClick = () => {
        setIsMoneyWithoutChange(!isMoneyWithoutChange)
        setData({...data, change: null})
    }

    const checkCurrentTime = () => {
        const startHours = 21;
        const startMinutes = 30;
        const endHours = 23;
        const currentDate = new Date();

        if ( currentDate.getHours() >= startHours && currentDate.getHours() < endHours ) {
            if ( currentDate.getHours() === startHours ) {
                if ( currentDate.getMinutes() >= startMinutes ) {
                    return true;
                }
            } else {
                return true;
            }
        }

        return false;
    }

    return <div className={styles.cart}>
        <div className={styles.colProducts}>
            {context.settings.isMobile ? <div className={styles.title}>
                <span>корзина</span>
                <img src={titleShevron} alt=""/>
            </div> : <div className={styles.title}>оформление заказа</div>}
            {errors ? <div className={styles.error}>
                <img src={error} alt=""/>
                <span>{errors}</span>
            </div> : null}
            {checkCurrentTime() ? <p className={styles.timeText}>Оплата после 21:30 возможна ТОЛЬКО за наличный расчет или онлайн на сайте</p> : null}
            {Object.values(context.cart.state.products).length > 0 ? <>
                    <div className={styles.products}>
                        {Object.values(context.cart.state.products).map((el, k) => {
                            return context.settings.isMobile ? _mobileProduct(el) : _desktopProduct(el)
                        })}
                    </div>
                    <div className={styles.persons}>
                        <div className={styles.personsIcon}>
                            <img src={context.settings.isMobile ? personsMob : persons} alt=""/>
                            <div className={styles.personsText}>приборы</div>
                        </div>
                        <div className={styles.counter}>
                            <img src={minus} alt="" onClick={() => {
                                if (data.persons > 0) {
                                    setData({...data, persons: data.persons - 1});
                                }
                            }}/>
                            <div className={styles.counterCount}>{data.persons === null ? 0 : data.persons}</div>
                            <img src={plus} alt="" onClick={() => {
                                if (data.persons === null ) {
                                    setData({...data, persons: 1});
                                } else {
                                    setData({...data, persons: Number(data.persons) + 1});
                                }
                            }}/>
                        </div>
                    </div>
                    <div className={styles.delivery}>
                        {context.cart.state.delivery.type === "delivery" ?
                            <>
                                {_deliveryPrice()}
                            </>
                            : null}
                        {context.cart.state.delivery.type === "pickup" && data.payment === "cash" ?
                            <div className={styles.heading}>
                                <span>скидка самовывоз</span>
                                <span className={styles.pickupDiscount}>-15%</span>
                            </div>
                            : null}
                    </div>
                    <div className={styles.line}/>
                    <div
                        className={classNames(styles.promo, promoValid === true ? styles.promoValid : '', promoValid === false ? styles.promoInvalid : '')}>
                        <input type="text"
                               readOnly={promoValid}
                               onChange={(e) => {
                                   setPromoValid(null)
                                   setData({...data, promocode: e.currentTarget.value})
                               }}
                               value={data.promocode}
                               placeholder={'Введите промокод'}
                               className={styles.input}/>
                        {promoError ?
                            <span className={styles.promoError}>{promoError}</span>
                            :
                            promoApply ?
                                <span className={styles.promoApply}>Промокод применен</span>
                                :
                                promoValid ?
                                    <span className={styles.promoButton} onClick={() => clearPromocode()}>очистить</span>
                                    :
                                    <span className={styles.promoButton} onClick={() => applyPromocode()}>применить</span>}
                    </div>
                    <div className={styles.sum}>
                        <div className={styles.sumLabel}>Итого к оплате</div>
                        <div className={styles.sumPrice}>
                            {_cartSum()}
                        </div>
                    </div>
                    {context.cart.state.delivery.type === "pickup" && data.payment === "cash" ?
                        <div className={styles.sumTextContainer}>
                            <p className={styles.sumText}>Скидка не отображается? - Мы уже чиним!<br />
                            Приносим извинения за неудобства!
                            </p>
                        </div> : null}
                </> :
                <div className={styles.cartEmpty}>
                    <img src={cartEmpty} alt=""/>
                    <div className={styles.cartEmptyText}>попробуйте хиты корейской кухни</div>
                    <div onClick={() => {
                        context.modals.cart.dispatch({type: "close"})
                        history.push(generatePath(publicRoutes.delivery.path, {}))
                    }} className={styles.cartEmptyButton}>
                        меню
                    </div>
                </div>
            }
        </div>
        <div className={styles.colContacts}>
            {context.settings.isMobile ? <div className={styles.title}>
                <span>оформление заказа</span>
                <img src={titleShevron} alt=""/>
            </div> : null}
            <div className={styles.contacts}>
                <div className={styles.heading}>контакты</div>
                <div className={styles.row}>
                    <input type="text"
                           onChange={(e) => setData({...data, name: e.currentTarget.value})}
                           placeholder={'Ваше имя'}
                           autoComplete={"name"}
                           value={data.name}
                           className={styles.input}/>
                    <InputMask className={styles.input}
                               value={data.phone}
                               readOnly={showSMS}
                               formatChars={{
                                   '9': '[0-9]',
                                   '7': '[7-8]'
                               }}
                               onChange={e => setData({...data, phone: e.currentTarget.value, withoutCall: false})}
                               mask="+7 (999) 999-99-99"
                               placeholder={'Номер телефона'}
                               maskChar="_"/>
                </div>
                <div className={styles.row}>
                    <Checkbox checked={data.withoutCall}
                              onClick={() => sendOTP()}
                              text={"Не звонить для подтверждения"}/>
                </div>
                {showSMS ? <div className={styles.rowHalf}>
                    <InputMask className={styles.input}
                               readOnly={inProgress}
                               formatChars={{
                                   '9': '[0-9]',
                               }}
                               onChange={e => verifyOTP(e.currentTarget.value)}
                               mask="9 9 9 9"
                               inputMode="numeric"
                               pattern="[0-9]"
                               autoComplete="one-time-code"
                               placeholder={'Код из SMS'}
                               maskChar="_"/>
                </div> : null}
            </div>
            <div className={styles.address}>
                <div className={styles.heading}>
                    <span>
                        {context.cart.state.delivery.type === 'delivery' ? 'адрес доставки' : 'самовывоз'}
                    </span>
                    <span className={styles.changeDeliveryType} onClick={() => changeDeliveryType()}>
                        {context.cart.state.delivery.type === 'delivery' ? 'заберу из ресторана' : 'заказать доставку'}
                    </span>
                </div>
                <div className={styles.row}>
                    <div className={styles.addressInput}>
                        <input type="text"
                               ref={addressInput}
                               placeholder={'Ваш адрес'}
                               readOnly={true}
                               value={deliveryAddress}
                               className={styles.input}/>
                        <span className={styles.addressChange} onClick={() => changeAddress()}>изменить адрес</span>
                    </div>

                </div>
                {context.cart.state.delivery.type === 'delivery' ? <div className={styles.row}>
                    <input type="text"
                           onChange={(e) => setData({...data, flat: e.currentTarget.value})}
                           placeholder={'кв/офис'}
                           value={data.flat}
                           className={styles.input}
                    />
                    <input type="text"
                           onChange={(e) => setData({...data, floor: e.currentTarget.value})}
                           placeholder={'этаж'}
                           value={data.floor}
                           className={styles.input}/>
                    <input type="text"
                           onChange={(e) => setData({...data, porch: e.currentTarget.value})}
                           placeholder={'подъезд'}
                           value={data.porch}
                           className={styles.input}/>
                    <input type="text"
                           onChange={(e) => setData({...data, intercom: e.currentTarget.value})}
                           placeholder={'домофон'}
                           value={data.intercom}
                           className={styles.input}/>
                </div> : null}
                <div className={classNames(styles.deliveryTime, styles.row)}>
                    <Checkbox checked={data.customTime}
                              className={styles.selectTime}
                              onClick={() => {
                                  setShowDatepicker(!showDatepicker)
                                  setData({...data, customTime: false})
                              }}
                              text={data.customTime ? formatDate(data.date) : "Выбрать дату и время"}/>
                    {!data.customTime && !context.settings.isMobile && context.cart.state.delivery.type !== null ?
                        <div className={styles.deliveryTimeLabel}>
                            <img src={redclock} alt=""/>
                            <span>{context.cart.state.delivery.type === 'delivery'
                                ? context.cart.state.delivery.zone.deliveryTime || 'Укажите адрес доставки'
                                : "Заказ будет готов в течении 25 минут"}</span>
                        </div> : null}
                    {data.customTime ? <div className={styles.deliveryTimeLabel}>
                        <img src={redclock} alt=""/>
                        <span>{_deliveryIntervalString()}</span>
                    </div> : null}
                </div>
                {showDatepicker ? <div className={styles.row}>
                    <CalendarSelector
                        inDate={today}
                        maxDate={maxDate}
                        withInterval={true}
                        isDelivery={context.cart.state.delivery.type === "delivery"}
                        onSuccess={setCustomTime}
                    />
                </div> : null}
            </div>
            <div className={styles.payment}>
                <div className={styles.heading}>Оплата</div>
                {context.cart.state.delivery.zone.cardOnly ? <div className={styles.row}>
                    <span className={styles.paymentAlert}>Временно возможна только онлайн оплата</span>
                </div> : null}
                <div className={classNames(styles.row, styles.paymentRow)}>
                    {Object.entries(context.cart.state.delivery.zone.payment || []).map(([key, val]) => {
                        return context.common.state.operating === false && key === "online"
                            ? null :
                            <div onClick={() => setData({...data, payment: key})}
                                 className={classNames(styles.paymentType, data.payment === key ? styles.active : 'null')}>
                                {val}
                            </div>
                    })}
                </div>
                {data.payment === "cash" ?
                <div className={styles.changeWrapper}>
                    <div className={styles.rowHalf}>
                        <input type="text"
                            onChange={(evt) => setData({...data, change: evt.currentTarget.value})}
                            placeholder={'Сдача с суммы ₽'}
                            value={isMoneyWithoutChange ? '' : data.change}
                            className={styles.input}
                            disabled={isMoneyWithoutChange}
                        />
                    </div>
                    <Checkbox
                        checked={isMoneyWithoutChange}
                        onClick={handleMoneyWithoutClick}
                        text={'Без сдачи'}
                    />
                </div> : null}
                <div className={styles.row}>
                    <textarea
                        onChange={(e) => setData({...data, comment: e.currentTarget.value})}
                        placeholder={'Комментарий к заказу'}
                        value={data.comment}
                        className={styles.textarea}/>
                </div>
                <div className={classNames(styles.row, styles.agreement)}>
                    <AgreementCheckbox checked={data.agreement}
                                       onClick={() => setData({...data, agreement: !data.agreement})}/>
                </div>
            </div>
            <div onClick={() => checkout()}
                 className={classNames(styles.button, context.cart.state.total > 0 && data.agreement ? styles.active : '')}>оформить
                заказ
            </div>
        </div>
    </div>
}
