import {Link, useHistory} from "react-router-dom";

import arrowRight from '../../../static/icons/catalog/arrow_right.svg'

import styles from "./PromoCategory.module.css"
import {useContext, useState} from "react";
import {AppContext} from "../../../store/context";
import {STATIC_HOST_BANNERS} from "../../../const";
import {classNames} from "../../../helper";

export const BannerSection = () => {
    const {context} = useContext(AppContext);

    const history = useHistory()

    return <div className={styles.promoInner}>
        <div className={styles.promoRow}>
            {context.settings.isMobile ? <PromoCategoryExpandedMobile/> : <PromoCategoryExpanded/>}
        </div>
        <div className={styles.promoRow}>
            {context.common.state.banners.delivery?.small?.map(el => {
                return <div className={styles.card}>
                    <div className={styles.cardHeader}>
                        <div className={styles.cardTitle} onClick={() => history.push(el.link)}>
                            <span>{el.title}</span>
                            {context.settings.isMobile ? <span><img src={arrowRight} alt=""/></span> : null}
                        </div>
                        <div className={styles.cardLink}>
                            <Link to={el.link}>перейти в раздел</Link>
                        </div>
                    </div>
                    <div className={styles.cardImage}>
                        <img onClick={() => history.push(el.link)}
                             src={STATIC_HOST_BANNERS + el.image.source} alt={el.image?.alt}/>
                    </div>
                </div>
            })}
        </div>
    </div>
}
export const PromoCategoryExpanded = () => {
    const {context} = useContext(AppContext)
    const history = useHistory()

    const [selected, setSelected] = useState(0);

    return <div className={styles.cardsExpanded}>
        {context.common.state.banners.delivery.big.slice(0, 2).map((el, i) => {
            return <>
                <div className={styles.cardImageExpanded} onClick={() => setSelected(i)}>
                    <img src={STATIC_HOST_BANNERS + el.image?.source} alt={el.image?.alt}/>
                </div>
                <div className={classNames(styles.cardExpandedText, selected === i ? styles.active : '')}>
                    <div className={styles.cardExpandedTextInner}>
                        <div className={styles.cardExpandedHeader}>
                            <div className={styles.cardTitle}>{el.title}</div>
                            <div className={styles.cardLink}>
                                <Link to={el.link}>перейти в раздел</Link>
                            </div>
                        </div>
                        <div className={styles.cardText} dangerouslySetInnerHTML={{__html: el.text}}/>
                        <div className={styles.cardArrow}>
                            <img src={arrowRight} onClick={() => history.push(el.link)}/>
                        </div>
                    </div>
                </div>
            </>
        })}
    </div>
}

export const PromoCategoryExpandedMobile = () => {
    const {context} = useContext(AppContext)
    const history = useHistory()

    const [selected, setSelected] = useState(0);

    return <div className={classNames(styles.cardsExpanded, selected === 0 ? styles.first : styles.second)}>
        {context.common.state.banners.delivery.big.map((el, i) => {
            return <div className={styles.cardExpanded}>
                <div className={styles.cardImageExpanded} onClick={() => setSelected(i)}>
                    <img src={STATIC_HOST_BANNERS + el.image?.source} alt={el.image?.alt}/>
                </div>

            </div>
        })}
        <div className={styles.cardExpandedText}>
            <div className={styles.cardExpandedHeader}>
                <div className={styles.cardTitle}>{context.common.state.banners.delivery.big[selected]?.title}</div>
                <div className={styles.cardLink}>
                    <Link to={context.common.state.banners.delivery.big[selected]?.link}>перейти в раздел</Link>
                </div>
            </div>
            <div onClick={() => { history.push(context.common.state.banners.delivery.big[selected]?.link)}} className={styles.cardText}
                 dangerouslySetInnerHTML={{__html: context.common.state.banners.delivery.big[selected]?.text}}/>
            <div className={styles.cardArrow}>
                <img src={arrowRight}
                     onClick={() => history.push(context.common.state.banners.delivery.big[selected]?.link)}/>
            </div>
        </div>
    </div>
}


