import React, {useContext, useState} from 'react'
import {CallbackForm} from "../../common/Form";

import {ReactComponent as Arrow} from '../../../static/icons/arrow_up.svg'

import styles from './Questions.module.css'
import {AppContext} from "../../../store/context";


export const Questions = () => {
    const {context} = useContext(AppContext)
    const [active, setActive] = useState(0);

    return <div className={styles.container}>
        <div className={styles.title}>
            <div className={styles.preHead}>우리에게 쓰기</div>
            <h2 className={styles.head}>остались вопросы? напишите нам!</h2>
        </div>
        <div className={styles.inner}>
            <div className={styles.form}>
                <CallbackForm/>
            </div>
            <div className={styles.faq}>
                {context.common.state.faq.map((el, i) => {
                    return <Question item={el} isOpen={active === i} onClick={() => {setActive(i)}}/>
                })}
            </div>
        </div>
    </div>
};

const Question = ({item, isOpen, onClick}) => {
    return <div className={`${styles.faqQuestion} ${isOpen ? styles.active: ''}`}>
        <div className={styles.faqQuestionTitle} onClick={onClick}>
            <div className={styles.faqQuestionTitleText}>{item.question}</div>
            <div className={styles.faqQuestionTitleIcon}><Arrow/></div>
        </div>
        <div className={styles.faqQuestionInner}>
            <div className={styles.faqQuestionText}>
                {item.answer}
            </div>
        </div>
    </div>
};
