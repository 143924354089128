export const CommonInitialState = {
    heroShown: false,
    loaded: false,
    categories: [],
    restaurants: [],
    hits: [],
    banners: {},
    actions: [],
    recommendations: [],
    faq: [],
    testimonials: [],
    seo: {
        title: null,
        description: null,
        bottomText: null
    },
    operating: true,
};
export const CommonReducer = (state, action) => {
    switch (action.type) {
        case 'loaded':
            return {...state, loaded: true};
        case 'setInitial':
            return {
                ...state,
                banners: action.payload.banners,
                categories: action.payload.categories,
                restaurants: action.payload.restaurants,
                recommendations: action.payload.recommendations,
                hits: action.payload.hits,
                actions: action.payload.actions,
                faq: action.payload.faq,
                testimonials: action.payload.testimonials,
                operating: action.payload.operating,
            };
        case 'setOperating':
            return {...state, operating: action.payload};
        case 'setSeo':
            return {...state, seo: action.payload};
        case 'heroShown':
            return {...state, heroShown: true};
        default:
            throw new Error();
    }
};

export const CustomerInitialState = {
    customer: {
        name: null,
        phone: null,
    },
    isAuth: false
};
export const CustomerReducer = (state, action) => {
    switch (action.type) {
        case 'set':
            return {...state, customer: action.payload, isAuth: true};
        case 'exit':
            return {...CustomerInitialState}
        default:
            throw new Error();
    }
};

export const ProductModalInitialState = {
    open: false,
    available: false,
    product: null,
    option: null,
    openScrolling: false,
}
export const ProductModalReducer = (state, action) => {
    switch (action.type) {
        case 'open':
            return {...state, open: true, product: action.payload};
        case 'close':
            return {...ProductModalInitialState};
        case 'setOption':
            return {...state, option: action.payload};
        case 'setAvailable':
            return {...state, available: action.payload};
        case 'setOpenScrolling':
            return {...state, openScrolling: action.payload};
        default:
            throw new Error();
    }
}

export const ActionModalInitialState = {
    action: null,
    open: false
}

export const ActionModalReducer = (state, action) => {
    switch (action.type) {
        case 'open':
            return {...state, open: true};
        case 'close':
            return {...state, open: false};
        case 'show':
            return {open: true, action: action.payload}
        default:
            throw new Error();
    }
}

export const ActionOnFuckingSinglePageModalInitialState = {
    open: false
}

export const ActionOnFuckingSinglePageModalReducer = (state, action) => {
    switch (action.type) {
        case 'open':
            return {...state, open: true};
        case 'close':
            return {...state, open: false};
        default:
            throw new Error();
    }
}

export const PersonalModalInitialState = {
    open: false
}

export const PersonalModalReducer = (state, action) => {
    switch (action.type) {
        case 'open':
            return {...state, open: true};
        case 'close':
            return {...state, open: false};
        default:
            throw new Error();
    }
}

export const RestaurantModalInitialState = {
    selected: null,
    open: false
}

export const RestaurantModalReducer = (state, action) => {
    switch (action.type) {
        case 'open':
            return {...state, open: true};
        case 'close':
            return {...state, open: false};
        case 'show':
            return {open: true, selected: action.payload}
        case 'select':
            return {...state, selected: action.payload}
        default:
            throw new Error();
    }
}

export const AddressModalInitialState = {
    open: false,
    operation: null,
    address: null,
    callback: null,
}
export const AddressModalReducer = (state, action) => {
    switch (action.type) {
        case 'open':
            return {
                ...state,
                open: true,
                operation: action.payload?.operation,
                callback: action.payload?.callback,
                address: action.payload?.address
            };
        case 'close':
            return {...state, open: false, operation: null, next: null};
        default:
            throw new Error();
    }
}

export const CartModalInitialState = {
    open: false
}
export const CartModalReducer = (state, action) => {
    switch (action.type) {
        case 'open':
            return {...state, open: true};
        case 'close':
            return {...state, open: false};
        default:
            throw new Error();
    }
}

export const CartReducer = (state, action) => {
    switch (action.type) {
        case 'setCart':
            return {
                ...state,
                count: action.payload.count,
                sum: action.payload.sum,
                products: action.payload.products,
                recommendations: action.payload.recommendations,
                verified: action.payload.verified,
                total: action.payload.total,
                promocode: action.payload.promocode,
                discount: action.payload.discount,
                delivery: {
                    address: action.payload.delivery.address,
                    latitude: action.payload.delivery.latitude,
                    longitude: action.payload.delivery.longitude,
                    type: action.payload.delivery.type,
                    zone: action.payload.delivery.zone,
                    price: action.payload.delivery.price,
                    freeFrom: action.payload.delivery.freeFrom,
                    payment: action.payload.delivery.payment,
                    time: action.payload.delivery.time,
                    cardOnly: action.payload.delivery.cardOnly,
                    paidOnly: action.payload.delivery.paidOnly,
                },
            }
        default:
            throw new Error();
    }
};
export const CartInitialState = {
    count: 0,
    sum: 0,
    total: 0,
    discount: 0,
    promocode: null,
    products: [],
    recommendations: [],
    verified: false,
    delivery: {
        address: null,
        latitude: null,
        longitude: null,
        price: 0,
        freeFrom: 1000,
        payment: [],
        time: null,
        type: null,
        zone: null,
        cardOnly: false,
        paidOnly: false
    },
};

export const MenuModalInitialState = {
    open: false
}

export const MenuModalReducer = (state, action) => {
    switch (action.type) {
        case 'open':
            return {...state, open: true};
        case 'close':
            return {...state, open: false};
        default:
            throw new Error();
    }
}
export const NavModalInitialState = {
    open: false
}

export const NavModalReducer = (state, action) => {
    switch (action.type) {
        case 'open':
            return {...state, open: true};
        case 'close':
            return {...state, open: false};
        default:
            throw new Error();
    }
}

export const OperatingModalInitialState = {
    open: false
}

export const OperatingModalReducer = (state, action) => {
    switch (action.type) {
        case 'open':
            return {...state, open: true};
        case 'close':
            return {...state, open: false};
        default:
            throw new Error();
    }
}

export const GiftCertificateModalInitialState = {
    open: false
}

export const GiftCertificateModalReducer = (state, action) => {
    switch (action.type) {
        case 'open':
            return {...state, open: true};
        case 'close':
            return {...state, open: false};
        default:
            throw new Error();
    }
}
