import {useContext, useEffect, useState} from "react";
import {Helmet} from 'react-helmet'

import {DEFAULT_DESCRIPTION, DEFAULT_TITLE} from "../../const";
import {AppContext} from "../../store/context";

export const Head = () => {
    const {context} = useContext(AppContext);
    const [title, setTitle] = useState(DEFAULT_TITLE);
    const [description, setDescription] = useState(DEFAULT_DESCRIPTION);

    useEffect(() => {
        setTitle(context.common.state.seo.title || DEFAULT_TITLE);
        setDescription(context.common.state.seo.description || DEFAULT_DESCRIPTION);
    }, [context.common.state.seo]);

    return <Helmet>
        <title>{title}</title>
        <meta name="description" content={description}/>
    </Helmet>
}

export const BottomSeo = () => {
    const {context} = useContext(AppContext);
    const [text, setText] = useState(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setText(context.common.state.seo.bottomText)
    }, [context.common.state.seo]);

    return text ? <div className="container" style={{position: "relative"}}>
        <div className={`text-muted ${open ? '' : 'text-muted--collapsed'}`} dangerouslySetInnerHTML={{__html: text}}/>
        <div className={open ? 'text-muted--hide' : 'text-muted--show'} onClick={() => {
            setOpen(!open)
        }}>{open ? 'скрыть' : 'подробнее'}</div>
    </div> : null
}
