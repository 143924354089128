import React, {useContext} from 'react'
import {generatePath} from "react-router-dom";

import {LoaderFullsize} from "../../components/common/Loader";
import {RedButton, WhiteButton} from "../../components/common/Buttons";
import {AppContext} from "../../store/context";
import {STATIC_HOST_PRODUCT} from "../../const";
import {publicRoutes} from "../../routing";

import styles from './Order.module.css'

export default class Order extends React.Component {
    constructor() {
        super()

        this.state = {
            loaded: false,
            order: null,
        }
    }

    hashFromProps(props) {
        return props.match.params.hash
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
        const hash = this.hashFromProps(this.props);

        this.context.context.processor.screen.order(hash)
            .then(res => {
                this.setState({
                    order: res.order,
                    loaded: true
                })
            })
            .catch(err => {
                this.history.push("/")
            })
    }

    componentDidUpdate(prevProps) {
        if (this.hashFromProps(this.props) !== this.hashFromProps(prevProps)) {
            this.componentDidMount()
        }
    }

    mapPayment() {
        switch (this.state.order.payment) {
            case 'online':
                return 'Онлайн на сайте'
            case 'card':
                return 'Банковской картой'
            case 'cash':
                if (this.state.order.info.cashChange) {
                    return `Наличными, сдача с ${this.state.order.info.cashChange} ₽`
                }
                return 'Наличными'
        }
    }

    render() {
        return this.state.loaded ? <div className={styles.container}>
            <div className={styles.title}>
                <div className={styles.titleText}>заказ №{this.state.order.id} оформлен</div>
            </div>
            <div className={styles.inner}>
                <div className={styles.description}>
                    <div className={styles.text}>
                        В течении 10 минут оператор направит Вам СМС с подтверждением заказа.
                        Пожалуйста, держите телефон включенным.
                    </div>
                    <div className={styles.call}>Если СМС Вам не пришло, пожалуйста, <a href="tel:78126034747">перезвоните нам</a></div>
                    <div className={styles.grid}>
                        <div className={styles.gridItem}>
                            <div className={styles.gridTitle}>когда доставить</div>
                            <div className={styles.gridText}>{this.state.order.time}</div>
                        </div>
                        <div className={styles.gridItem}>
                            <div className={styles.gridTitle}>куда доставить</div>
                            <div className={styles.gridText}>{this.state.order.address}</div>
                        </div>
                        <div className={styles.gridItem}>
                            <div className={styles.gridTitle}>Комментарий</div>
                            <div className={styles.gridText}>{this.state.order.comment}</div>
                        </div>
                        <div className={styles.gridItem}>
                            <div className={styles.gridTitle}>Оплата</div>
                            <div className={styles.gridText}>{this.mapPayment()}</div>
                        </div>
                    </div>
                    <p className={`${styles.text} ${styles.textRed}`}>
                        При первом заказе оператор позвонит для подтверждения, чтобы его обработать качественно и быстро
                    </p>
                    {!this.context.context.settings.isMobile ? <div className={styles.buttons}>
                        <RedButton text={"история заказов"} link={generatePath(publicRoutes.personal.path)}/>
                        <WhiteButton text={"на главную"} link={generatePath(publicRoutes.main.path)}/>
                    </div> : null}
                </div>
                <div className={styles.orderInfo}>
                    <div className={styles.products}>
                        {this.state.order.products.map(el => {
                            return el.name !== 'Доставка' ? <OrderProduct product={el}/> : null
                        })}
                    </div>
                    <div className={styles.productTotal}>
                        <div className={styles.productTotalRow}>
                            <div className={styles.productTotalLabel}>сумма заказа</div>
                            <div className={styles.productTotalPrice}>{this.state.order.sum} ₽</div>
                        </div>
                        {this.state.order.discount > 0 ?
                            <div className={styles.productTotalRow}>
                                <div className={styles.productTotalLabel}>cкидка</div>
                                <div>{this.state.order.discount} ₽</div>
                            </div> : null}
                            <div className={styles.productTotalRow}>
                                <div className={styles.productTotalLabel}>доставка</div>
                                <div className={styles.productTotalPrice}>{this.state.order.deliveryPrice} ₽</div>
                            </div>
                    </div>
                    <div className={styles.orderTotal}>
                        <div className={styles.orderTotalLabel}>итого к оплате</div>
                        <div className={styles.orderTotalPrice}>{this.state.order.total} ₽</div>
                    </div>
                </div>
                {this.context.context.settings.isMobile ? <div className={styles.buttons}>
                    <RedButton text={"история заказов"} link={generatePath(publicRoutes.personal.path)}/>
                    <WhiteButton text={"на главную"} link={generatePath(publicRoutes.main.path)}/>
                </div> : null}
            </div>
        </div> : <LoaderFullsize/>
    }
}

const OrderProduct = ({product}) => {
    const {context} = useContext(AppContext)

    return context.settings.isMobile ? <div className={styles.product}>
        <div className={styles.productImage}>
            <img src={STATIC_HOST_PRODUCT + product.image} alt=""/>
        </div>
        <div className={styles.productTitle}>
            <div className={styles.productName}> {product.name}</div>
        </div>
        <div className={styles.productSub}>
            {product.option ? <span>{product.optionName}</span> : null}
            {product.weight ? <span>{product.weight}</span> : null}
        </div>
        <div className={styles.productNumbers}>
            <div className={styles.productCount}>{product.count} шт</div>
            <div className={styles.productPrice}>{product.price * product.count} ₽</div>
        </div>
    </div> : <div className={styles.product}>
        <div className={styles.productImage}>
            <img src={STATIC_HOST_PRODUCT + product.image} alt=""/>
        </div>
        <div className={styles.productTitle}>
            <div className={styles.productName}> {product.name}</div>
            <div className={styles.productSub}>
                {product.option ? <span>{product.optionName}</span> : null}
                {product.weight ? <span>{product.weight}</span> : null}
            </div>
        </div>
        <div className={styles.productCount}>{product.count} шт</div>
        <div className={styles.productPrice}>{product.price * product.count} ₽</div>
    </div>
}

Order.contextType = AppContext;
