import React, {useContext, useState} from 'react'
import {generatePath} from "react-router-dom";

import {AppContext} from "../../store/context";
import {ProductCard} from "../../components/sections/catalog/ProductCard";
import {Loader} from "../../components/common/Loader";
import {publicRoutes} from "../../routing";
import {FILTER_IMAGE_MAP} from "../../const";
import {classNames} from "../../helper";
import {ResponsiveProductModal} from "../../components/modals/Product";

import cross from '../../static/icons/catalog/filter_cross.svg'

import styles from './Category.module.css'
import arrowdown from "../../static/icons/restaurant/shevron-down.svg";
import arrowup from "../../static/icons/restaurant/shevron-up.svg";

export default class Category extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loaded: false,
            selected: null,
            modalOpen: false,
            category: null,
            products: [],
            filter: null,
            tags: [],
        }
    }

    categoryFromProps(props) {
        return props.match.params.hasOwnProperty('slug')
            ? props.match.params.slug
            : null
    }

    productFromProps(props) {
        return props.match.params.hasOwnProperty('product')
            ? props.match.params.product
            : null
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});

        const slug = this.categoryFromProps(this.props)
        const category = this.context.context.common.state.categories.find(el => el.slug === slug)

        if (!category) {
            this.props.history.push(generatePath(publicRoutes.notFound.path))
            return
        }

        this.context.context.common.dispatch({
            type: 'setSeo',
            payload: {
                title: category.seo.title,
                description: category.seo.description,
            }
        })

        this.setState({category: category, loaded: false})

        this.context.context.processor.catalog.category(category.slug)
            .then(res => {
                if (this.productFromProps(this.props)) {
                    this.openProduct(res.products)
                }

                this.setState({
                    loaded: true,
                    products: res.products,
                    tags: res.tags
                })
            })
            .catch(err => {

            })
    }

    openProduct(products) {
        if (!products) {
            return;
        }

        const product = this.productFromProps(this.props)
        if (product) {
            const found = products.find(el => el.slug === product)
            if (!found) {
                return
            }

            this.setState({selected: found, modalOpen: true})
        }
    }


    componentDidUpdate(prevProps, prevState) {
        if (this.categoryFromProps(this.props) !== this.categoryFromProps(prevProps)) {
            this.setState({filter: null})
            this.componentDidMount()
            return
        }

        if (this.productFromProps(this.props) !== this.productFromProps(prevProps)) {
            if (this.productFromProps(this.props)) {
                this.openProduct(this.state.products)
            }
        }

        if (prevState.modalOpen === true && this.state.modalOpen === false) {
            this.context.context.common.dispatch({
                type: 'setSeo',
                payload: {
                    title: this.state.category.seo.title,
                    description: this.state.category.seo.description,
                }
            })

            this.props.history.push(generatePath(publicRoutes.deliveryCategory.path[0], {
                slug: this.state.category.slug,
            }))
        }
    }

    render() {
        return <div className={styles.wrapper}>
            <div className={styles.category}>
                <div className={styles.sectionTitle}>{this.state.category?.title}</div>
                {this.state.category?.seo.htmlBefore
                    ? <div className={styles.textBefore}
                           dangerouslySetInnerHTML={{__html: this.state.category?.seo.htmlBefore}}/>
                    : null}
                {this.state.loaded ?
                    <>
                        {this.state.tags?.find(el => el.slug.startsWith("filtr", 0)) ?
                            <Filters
                                tags={this.state.tags}
                                active={this.state.filter}
                                onSelect={(filter) => this.setState({filter: filter})}/>
                            : null}
                        <div className={styles.products}>
                            {this.state.products.map(el => {
                                if (this.state.filter === null) {
                                    return el.active
                                        ? <ProductCard product={el} category={this.state.category}/>
                                        : null
                                }

                                return el.active && el.tags.find(el => el.slug === this.state.filter.slug)
                                    ? <ProductCard product={el} category={this.state.category}/>
                                    : null
                            })}
                        </div>
                        {this.state.category?.seo.htmlAfter
                            ? <div className={styles.textAfter}
                                   dangerouslySetInnerHTML={{__html: this.state.category?.seo.htmlAfter}}/>
                            : null}
                    </>
                    : <div className={styles.loader}><Loader/></div>}
            </div>
            {this.state.loaded ?
                <ResponsiveProductModal product={this.state.selected}
                                        isOpen={this.state.modalOpen}
                                        onDismiss={() => this.setState({modalOpen: false})}
                /> : null}
        </div>
    }
}

const Filters = ({tags, active, onSelect}) => {
    const {context} = useContext(AppContext)
    if (context.settings.isMobile) {
        return <MobileFilters
            tags={tags}
            active={active}
            onSelect={onSelect}
        />
    }

    return <div className={styles.filters}>
        {tags?.map(el => {
            return FILTER_IMAGE_MAP.hasOwnProperty(el.slug)
                ? <div
                    className={classNames(styles.filter, el.slug === active?.slug ? styles.active : '')}
                    onClick={() => onSelect(active?.slug !== el.slug ? el : null)}>
                    <div className={styles.filterText}>{FILTER_IMAGE_MAP[el.slug].name}</div>
                    <div className={styles.filterIcon}>
                        <img src={FILTER_IMAGE_MAP[el.slug].img} alt=""/>
                    </div>
                </div> : null
        })}
        <div className={styles.filterDrop} onClick={() => onSelect(null)}>
            <div className={styles.filterText}>сбросить</div>
            <div className={styles.filterIcon}>
                <img src={cross} alt=""/>
            </div>
        </div>
    </div>
}


const MobileFilters = ({tags, active, onSelect}) => {
    const [open, setOpen] = useState(false)
    return <div className={styles.filters}>
        <div className={classNames(styles.filtersDropdown, open ? styles.active : '')}>
            <div className={styles.filtersDropdownLabel} onClick={() => {
                setOpen(!open)
            }}>
                <span>{active !== null ? FILTER_IMAGE_MAP[active.slug].name : "фильтры"}</span>
                <span><img src={open ? arrowup : arrowdown} alt=""/></span>
            </div>
            {open ? <div className={styles.filtersDropdownOptions}>
                {tags?.map(el => {
                    return FILTER_IMAGE_MAP.hasOwnProperty(el.slug)
                        ? <div
                            className={classNames(styles.filter, el.slug === active?.slug ? styles.active : '')}
                            onClick={() => {
                                onSelect(active?.slug !== el.slug ? el : null)
                                setOpen(false)
                            }}>
                            <div className={styles.filterIcon}>
                                <img src={FILTER_IMAGE_MAP[el.slug].img} alt=""/>
                            </div>
                            <div className={styles.filterText}>{FILTER_IMAGE_MAP[el.slug].name}</div>
                        </div> : null
                })}
            </div> : null}
        </div>
        <div className={styles.filterDrop} onClick={() => {
            onSelect(null)
            setOpen(false)
        }}>
            <div className={styles.filterIcon}>
                <img src={cross} alt=""/>
            </div>
        </div>
    </div>
}


Category.contextType = AppContext;