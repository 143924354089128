import React from "react";
import {Link} from "react-router-dom";

import {AppContext} from "../../store/context";

import img1 from '../../static/images/about/img1.png'
import img2 from '../../static/images/about/img2.png'
import img3 from '../../static/images/about/img3.png'
import img4 from '../../static/images/about/img4.png'
import img5 from '../../static/images/about/img5.png'
import img6 from '../../static/images/about/img6.png'
import img7 from '../../static/images/about/img7.png'
import img8 from '../../static/images/about/img8.png'
import img9 from '../../static/images/about/img9.png'
import img10 from '../../static/images/about/img10.png'
import img11 from '../../static/images/about/img11.png'
import img12 from '../../static/images/about/img12.png'
import back from "../../static/icons/conditions/back.svg";
import right from "../../static/icons/arrow_right.svg";

import styles from "./About.module.css";

export default class About extends React.Component {
    constructor() {
        super()

        this.state = {
            loaded: false,
            restaurant: null,
            activeChief: {
                img: img6,
                name: 'Евгений Ким (МАДАБАЙ)',
                work: 'Сооснователь и бренд-шеф',
                info: 'Отец-основатель корейского гастрономического направления в России, шеф-повар в третьем поколении. 22 года изучает и готовит блюда корейской кухни. Объединил в меню южнокорейскую, северокорейскую и кухню «корё-сарам».',
            },
            chiefs: [
                {
                    img: img7,
                    name: 'ДМИТРИЙ цой',
                    work: 'Сооснователь и бренд-шеф',
                    info: 'Креативный арт-директор, идейный вдохновитель и новатор-бунтарь. 20 лет изучает и готовит блюда корейской кухни. Прошел обучение в Южной Корее',
                },
                {
                    img: img8,
                    name: 'СВЕТЛАНА огай',
                    work: 'Бренд-шеф сети ресторанов',
                    info: 'Заботливый руководитель, требовательный наставник и сердце компании. 18 лет готовит и разрабатывает блюда в крупнейших ресторанных проектах Санкт-Петербурга.',
                }
            ],
            activeImage: {
                src: img9
            },
            images: [
                {
                    src: img10
                },
                {
                    src: img11
                }
            ]
        }
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    }

    nextChiefHandler = this.nextChief.bind(this)
    imageChangeHandler = this.imageChange.bind(this)

    nextChief() {
        const newChiefs = [...this.state.chiefs]
        const newActiveChief = newChiefs.shift()
        newChiefs.push(this.state.activeChief)
        this.setState({
            ...this.state,
            chiefs: newChiefs,
            activeChief: newActiveChief
        })
    }

    imageChange() {
        const newImages = [...this.state.images]
        const newActiveImage = newImages.shift()
        newImages.push(this.state.activeImage)
        this.setState({
            ...this.state,
            images: newImages,
            activeImage: newActiveImage
        })
    }

    render() {
        return <div className={styles.container}>
            <div className={styles.title}>
                <div className={styles.titleBack} onClick={this.props.history.goBack}>
                    <span><img src={back} alt=""/></span>
                    <span>назад</span>
                </div>
                <div className={styles.titleText}>
                    <h1>
                        О нас
                    </h1>
                </div>
            </div>
            <div className={styles.inner}>
                <div className={`${styles.innerContainer} ${styles.innerContainer1}`}>
                    <div className={styles.imageBlock1}>
                        <div className={styles.text1}>
                            <div>
                                <p>
                                    Кореана – сеть ресторанов, где более <span>10 лет</span> мы готовим вкусную корейскую кухню.
                                </p>
                                <p>
                                    Мы сохраняем аутентичность национальной еды, лишь немного адаптируя вкус, и знакомим гостей с культурой Кореи.
                                </p>
                            </div>
                            <span></span>
                        </div>
                        <div className={styles.img1}>
                            <img src={img1} alt=""/>
                        </div>
                    </div>
                    <div className={styles.imageBlock2}>
                        <div className={styles.text2}>
                            <div>
                                <p>
                                    한국 문화 레스토랑
                                </p>
                                <p>
                                    Самый простой способ прикоснуться к другой культуре – ощутить ее национальный вкус
                                </p>
                            </div>
                            <span></span>
                        </div>
                        <div className={styles.img2}>
                            <img src={img2} alt=""/>
                        </div>
                    </div>
                </div>

                <div className={`${styles.innerContainer} ${styles.innerContainer2}`}>
                    <div className={styles.innerTitle}>
                        <div className={styles.titleText}>
                            <h2>
                                Кореана – больше, чем корейская кухня:
                            </h2>
                        </div>
                    </div>
                    <div className={styles.imageBlock3}>
                        <div className={styles.text3}>
                            <div className={styles.text3InnerBlock}>
                                <p>
                                    <span>밝은</span>
                                    <span>
                                        насыщенная, яркая и эмоциональная!
                                    </span>
                                </p>
                                <p>
                                    Сильные эмоции – основа корейских дорам, k-pop музыки, боевых искусств и, конечно, традиционной кухни. Эмоции – пятая стихия корейцев наряду с огнем, водой, воздухом и землей, которую они сумели обуздать.
                                </p>
                            </div>
                            <div className={styles.text3InnerBlock}>
                                <p>
                                    <span>전통</span>
                                    <span>
                                        дань традициям и отражение многовековой культуры
                                    </span>
                                </p>
                                <p>
                                    Мы готовим по традиционным корейским рецептам, которые проверены временем, чтобы получить гарантированно вкусные и качественные блюда.
                                </p>
                            </div>
                            <div className={styles.text3InnerBlock}>
                                <p>
                                    <span>근면</span>
                                    <span>
                                        отражение неутомимого трудолюбия и внимания к деталям
                                    </span>
                                </p>
                                <p>
                                    Для нас важна каждая деталь: от контроля качества продуктов, разнообразия и совершенствования меню до подбора лучших поваров и официантов.
                                </p>
                            </div>
                        </div>
                        <div className={styles.img3}>
                            <img src={img3} alt=""/>
                        </div>
                    </div>
                </div>

                <div className={`${styles.innerContainer} ${styles.innerContainer3}`}>
                    <div className={styles.innerTitle4}>
                        <div className={styles.titleText}>
                            <h2>
                                Выбираем качественные ингредиенты:
                            </h2>
                        </div>
                    </div>
                    <div className={styles.imageBlock4}>
                        <div className={styles.img4}>
                            <img src={img4} alt=""/>
                        </div>
                        <div className={styles.imageBlock5}>
                            <div className={styles.text5}>
                                <p>
                                    Мы не экономим на продуктах и тщательно отбираем каждый ингредиент. 
                                </p>
                                <p>
                                    Капусту кимчи – основу корейской кухни – <span>выращиваем сами</span>, чтобы контролировать ее качество от семени до созревшего кочана. Планируем расширять собственное производство других ингредиентов.
                                </p>
                                <p>
                                    Ежегодно производим более <span>100 тонн</span> кимчи по уникальному рецепту от наших бренд-шефов.
                                </p>
                            </div>
                            <div className={styles.img5}>
                                <img src={img5} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${styles.innerContainer} ${styles.innerContainer4}`}>
                    <div className={styles.innerTitle}>
                        <div className={styles.titleText}>
                            <h2>
                                работаем с лучшими мастерами:
                            </h2>
                        </div>
                    </div>
                    <div className={styles.imageBlock6}>
                        <div className={styles.img6}>
                            <img src={this.state.activeChief.img} alt=""/>
                        </div>
                        <span
                            onClick={this.nextChiefHandler}
                            className={styles.rightArrow}
                        >
                            <img src={right} alt=""/>
                        </span>
                        <div className={styles.imageBlock7}>
                            <div className={styles.text7}>
                                <p>
                                    {this.state.activeChief.name}
                                </p>
                                <p>
                                    {this.state.activeChief.work}
                                </p>
                                <p>
                                    {this.state.activeChief.info}
                                </p>
                            </div>
                            <div className={styles.imgGroup}>
                                {
                                    this.state.chiefs.map((chief) => {
                                        return (
                                            <div className={styles.chiefCard}>
                                                <span>
                                                    <img src={chief.img} alt=""/>
                                                </span>
                                                <p>{chief.name}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        </div>
                    </div>
                </div>

                <div className={`${styles.innerContainer} ${styles.innerContainer5}`}>
                    <div className={styles.innerTitle}>
                        <div className={styles.titleText}>
                            <h2>
                                продвигаем корейскую культуру:
                            </h2>
                        </div>
                    </div>
                    <div className={styles.imageBlockWrapper}>
                        <div className={styles.imageBlock9}>
                            <div className={styles.text9}>
                                <p>
                                    Мы активно сотрудничаем с <span>Корейским     культурно-просветительским Центром</span> «Нан» и другими организациями.
                                </p>
                                <p>
                                    Совместно с ними проводим в наших ресторанах кулинарные мастер-классы, детские мероприятия, а также отмечаем все большие корейские праздники: корейский Новый год <Link to={"/blog/sollal"} className={styles.koreLink}>Сольналь</Link>, праздник урожая <Link to={"/blog/chhusok-korejskij-prazdnik"} className={styles.koreLink}>Чхусок</Link>, и другие.
                                </p>
                            </div>
                            <div className={styles.staticImage}>
                                <img src={img9} alt=""/>
                            </div>
                            <div className={styles.activeImage}>
                                <img src={this.state.activeImage.src} alt=""/>
                            </div>
                        </div>
                        <div className={styles.imageBlock10}>

                            <div className={styles.topImage}>
                                <img src={img10} alt=""/>
                            </div>

                            <div className={styles.bottomImage}>
                                <img src={img11} alt=""/>
                            </div>
                        </div>
                        <div className={styles.sliderBlock}>
                            {
                                this.state.images.map(image => {
                                    return (
                                        <div className={styles.topImage}>
                                            <img src={image.src} alt=""/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <span onClick={this.imageChangeHandler} className={styles.rightArrow9}><img src={right} alt=""/></span>
                    </div>
                </div>

                <div className={`${styles.innerContainer} ${styles.innerContainer6}`}>
                    <div className={styles.innerTitle}>
                        <div className={styles.titleText}>
                            <h2>
                                предлагаем искренний сервис:
                            </h2>
                        </div>
                    </div>
                    <div className={styles.imageBlock12}>
                        <div className={styles.text12}>
                            <p>
                                Благодаря центру «Нан» наши сотрудники регулярно проходят тренинги и лекции о тонкостях корейского гостеприимства, а наши рестораны украшают аутентичные декоративные элементы.
                            </p>
                            <p>
                                Мы чтим традиции и обычаи Кореи и тепло встречаем наших гостей с улыбкой и поклоном по-корейски.
                            </p>
                            <p>
                                Делимся эмоциями, теплом и яркими вкусами – во всех ресторанах «Кореана»
                            </p>
                            <p>
                                한국 문화 레스토랑
                            </p>
                        </div>
                        <div className={styles.img12}>
                            <img src={img12} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

About.contextType = AppContext;
