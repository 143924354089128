import React, {useContext, useState} from 'react'
import {generatePath} from "react-router-dom";

import {AppContext} from "../../store/context";
import {ProductCard} from "../../components/sections/catalog/ProductCard";
import {Loader} from "../../components/common/Loader";
import {publicRoutes} from "../../routing";
import {STATIC_HOST} from "../../const";
import {classNames} from "../../helper";
import {ResponsiveProductModal} from "../../components/modals/Product";

import cross from '../../static/icons/catalog/filter_cross.svg'

import styles from './KoreanProducts.module.css'
import arrowdown from "../../static/icons/restaurant/shevron-down.svg";
import arrowup from "../../static/icons/restaurant/shevron-up.svg";

const filters = [
    {name: "im-skidki", label: "Скидки"},
    {name: "im-koreana", label: "Для готовки"},
    {name: "im-lapsha", label: "Лапша и рамены"},
    {name: "im-sladkoe", label: "Сладкое и снеки"},
    {name: "im-sneki", label: "Снеки"},
    {name: "im-soysi", label: "Соусы и соевая паста"},
    {name: "im-nori", label: "Морская капуста"},
    {name: "im-napitki", label: "Напитки"},
]
export default class KoreanProducts extends React.Component {
    constructor(props) {
        super(props)

        this.categorySlug = 'korean-products'

        this.state = {
            loaded: false,
            selected: null,
            modalOpen: false,
            category: null,
            products: [],
            filter: null
        }
    }


    productFromProps(props) {
        return props.match.params.hasOwnProperty('product')
            ? props.match.params.product
            : null
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});

        this.context.context.processor.catalog.category(this.categorySlug)
            .then(res => {
                if (this.productFromProps(this.props)) {
                    this.openProduct(res.products)
                }

                this.setState({
                    category: res.category,
                    loaded: true,
                    products: res.products
                })
            })
            .catch(err => {

            })
    }

    openProduct(products) {
        if (!products) {
            return;
        }

        const product = this.productFromProps(this.props)
        if (product) {
            const found = products.find(el => el.slug === product)
            if (!found) {
                return
            }

            this.setState({selected: found, modalOpen: true})
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.productFromProps(this.props) !== this.productFromProps(prevProps)) {
            if (this.productFromProps(this.props)) {
                this.openProduct(this.state.products)
            }
        }

        if (prevState.modalOpen === true && this.state.modalOpen === false) {
            this.context.context.common.dispatch({
                type: 'setSeo',
                payload: {
                    title: this.state.category.seo.title,
                    description: this.state.category.seo.description,
                }
            })

            this.props.history.push(generatePath(publicRoutes.deliveryCategoryKoreanProducts.path[0]))
        }
    }

    setFilter(name) {
        const f = filters.find(el => el.name === name)
        if (f) {
            this.setState({...this.state, filter: f})
        }
    }

    render() {
        return <div className={styles.wrapper}>
            <div className={styles.category}>
                <div className={styles.sectionTitle}>Магазин корейских продуктов</div>
                <div className={styles.sectionDescription}>
                    <h2>Доставим товары из интернет-магазина вместе с едой</h2>
                    <p>Магазин корейских продуктов Koreana Маркет в Санкт-Петербурге предлагает большой
                        выбор рамёнов,
                        лапши, соевых паст и различных соусов, в том числе, соевых, снеков, чипсов, морской капусты,
                        напитков, кондитерских и фирменных изделий.</p>
                    <div className={styles.grid}>
                        <img onClick={() => this.setFilter('im-skidki')}
                             alt="распродажа кореана маркет 50% цены пополам"
                             src={STATIC_HOST + `marketing/акции и скидки интернет-магазин товаров из азии доставка по спб.jpg`}/>
                        <img onClick={() => this.setFilter('im-koreana')}
                             alt="Все для приготовления блюд корейской, японской, китайской кухни с доставкой по Санкт-петербургу"
                             src={STATIC_HOST + `marketing/все приготовления азиатских блюд в онлайн-магазине Санкт-петербург Кореана.jpg`}/>
                        <img onClick={() => this.setFilter('im-lapsha')}
                             alt="Купить лапшу быстрого приготовления из Кореи и Китая в СПБ с доставкой"
                             src={STATIC_HOST + `marketing/лапша и рамёны в онлайн магазине корейских продуктов Кореана.jpg`}/>
                        <img onClick={() => this.setFilter('im-soysi')}
                             alt="Соусы и соевая паста в интернет-магазине корейских товаров"
                             src={STATIC_HOST + `marketing/соусы. пасты и приправы для блюд корейской, японской и китайской кухни с доставкой в СПБ.jpg`}/>
                        <img onClick={() => this.setFilter('im-nori')}
                             alt="Ким, нори, жареные морские водоросли с доставкой на дом в Санкт-Петербурге"
                             src={STATIC_HOST + `marketing/морская капуста, ким, нори ламинария с доставкой по Санкт-Петербургу.jpg`}/>
                        <img onClick={() => this.setFilter('im-sladkoe')}
                             alt="Корейские снэки с доставкой в Санкт-Петербурге"
                             src={STATIC_HOST + `marketing/сладкое и снеки, пеперо, чипсы корейские товары в СПБ с доставкой.jpg`}/>
                        <img onClick={() => this.setFilter('im-napitki')}
                             alt="Корейские напитки в интернет магазине с доставкой на дом"
                             src={STATIC_HOST + `marketing/напитки корейские с доставкой Кореана СПБ.jpg`}/>
                    </div>
                </div>
                {this.state.loaded ?
                    <>

                        <Filters
                            active={this.state.filter}
                            onSelect={(filter) => this.setState({filter: filter})}/>

                        <div className={styles.products}>
                            {this.state.products.map(el => {
                                if (this.state.filter === null) {
                                    return el.active
                                        ? <ProductCard product={el} market={true}/>
                                        : null
                                }
                                return el.active && el.tags.find(el => el.slug === this.state.filter.name)
                                    ? <ProductCard product={el} market={true}/>
                                    : null
                            })}
                        </div>
                    </>
                    : <div className={styles.loader}><Loader/></div>}
            </div>
            {this.state.loaded ?
                <ResponsiveProductModal product={this.state.selected}
                                        isOpen={this.state.modalOpen}
                                        onDismiss={() => this.setState({modalOpen: false})}
                /> : null}
        </div>
    }
}

const Filters = ({active, onSelect}) => {
    const {context} = useContext(AppContext)
    if (context.settings.isMobile) {
        return <MobileFilters
            active={active}
            onSelect={onSelect}
        />
    }

    return <div className={styles.filters}>
        {filters.map(el => {
            return <div
                className={classNames(styles.filter, el.name === active?.name ? styles.active : '')}
                onClick={() => onSelect(active?.name !== el.name ? el : null)}>
                <div className={styles.filterText}>{el.label}</div>
            </div>
        })}
        <div className={styles.filterDrop} onClick={() => onSelect(null)}>
            <div className={styles.filterText}>сбросить</div>
            <div className={styles.filterIcon}>
                <img src={cross} alt=""/>
            </div>
        </div>
    </div>
}


const MobileFilters = ({active, onSelect}) => {
    const [open, setOpen] = useState(false)
    return <div className={styles.filters}>
        <div className={classNames(styles.filtersDropdown, open ? styles.active : '')}>
            <div className={styles.filtersDropdownLabel} onClick={() => {
                setOpen(!open)
            }}>
                <span>{active?.label || "фильтры"}</span>
                <span><img src={open ? arrowup : arrowdown} alt=""/></span>
            </div>
            {open ? <div className={styles.filtersDropdownOptions}>
                {filters.map(el => {
                    return <div
                        className={classNames(styles.filter, el.name === active?.name ? styles.active : '')}
                        onClick={() => {
                            onSelect(active?.name !== el.name ? el : null)
                            setOpen(false)
                        }}>
                        <div className={styles.filterText}>{el.label}</div>
                    </div>
                })}
            </div> : null}
        </div>
        <div className={styles.filterDrop} onClick={() => {
            onSelect(null)
            setOpen(false)
        }}>
            <div className={styles.filterIcon}>
                <img src={cross} alt=""/>
            </div>
        </div>
    </div>
}


KoreanProducts.contextType = AppContext;