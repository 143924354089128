import React, {useContext} from 'react'

import {AppContext} from "../../store/context";
import {classNames} from "../../helper";

import active from '../../static/icons/conditions/active.svg'
import inactive from '../../static/icons/conditions/inactive.svg'
import back from '../../static/icons/conditions/back.svg'
import rest from '../../static/icons/conditions/rest.svg'

import {ZoneMap} from "../../components/common/Map";

import styles from './Conditions.module.css'


const descriptions = {
    "green": {
        icon: styles.zoneDotGreen,
        name: "зеленая зона",
        sections: [
            {
                title: "Доставка 79 рублей",
                subs: ['при заказе от 1000 рублей']
            },
            {
                title: "ВРЕМЯ ДОСТАВКИ",
                subs: ['60-75 минут'],
            },
            {
                title: "типы оплаты",
                subs: [
                    "Наличными курьеру",
                    "Онлайн оплата на сайте",
                    "Банковской картой курьеру"
                ]
            }
        ]
    },
    "yellow": {
        icon: styles.zoneDotYellow,
        name: "желтая зона",
        sections: [
            {
                title: "Доставка 249 рублей",
                subs: ['при заказе от 1000 рублей']
            },
            {
                title: "ВРЕМЯ ДОСТАВКИ",
                subs: ['60-75 минут'],
            },
            {
                title: "типы оплаты",
                subs: [
                    "Наличными курьеру",
                    "Онлайн оплата на сайте",
                    "Банковской картой курьеру"
                ]
            }
        ]
    },
    "orange": {
        icon: styles.zoneDotOrange,
        name: "красная зона",
        sections: [
            {
                title: "Доставка 349 рублей",
                subs: ["при заказе от 1000 рублей"]
            },
            {
                title: "ВРЕМЯ ДОСТАВКИ",
                subs: ['75-90 минут'],
            },
            {
                title: "типы оплаты",
                subs: [
                    "Онлайн оплата на сайте",
                ]
            }
        ]
    },
}

export default class Conditions extends React.Component {
    constructor() {
        super()

        this.state = {
            selected: "green",
            mode: "zones"
        }
    }

    componentDidMount() {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});

        this.context.context.processor.screen.operating().then(res => {
            this.context.context.common.dispatch({type: 'setOperating', payload: res.operating})
        })
    }

    points() {
        return this.context.context.common.state.restaurants.map(el => {
            return {name: el.name, lng: el.coordinates.longitude, lat: el.coordinates.latitude, address: el.address}
        })
    }

    render() {
        return <div className={styles.container}>
            <div className={styles.title}>
                <div className={styles.titleBack} onClick={this.props.history.goBack}>
                    <span><img src={back} alt=""/></span>
                    <span>назад</span>
                </div>
                <div className={styles.titleText}>условия и зоны доставки</div>
            </div>
            {this.context.context.settings.isMobile ?
                <div className={styles.inner}>
                    <WorkHours/>
                    <div className={styles.map}>
                        <ZoneMap mode={this.state.mode} points={this.points()} height={"425px"}/>
                    </div>
                    <Zones selected={this.state.selected}
                           onModeChange={(mode) => this.setState({mode: mode})}
                           onZoneSelect={(el) => this.setState({mode: "zones", selected: el})}/>
                </div>
                : <div className={styles.inner}>
                    <div className={styles.map}>
                        <ZoneMap mode={this.state.mode} points={this.points()} height={"525px"}/>
                    </div>
                    <div className={styles.details}>
                        <WorkHours/>
                        <Zones selected={this.state.selected}
                               onModeChange={(mode) => this.setState({mode: mode})}
                               onZoneSelect={(el) => this.setState({mode: "zones", selected: el})}/>
                    </div>
                </div>
            }
        </div>
    }
}

const WorkHours = () => {
    const {context} = useContext(AppContext)

    return <div className={styles.workHours}>
        <div className={styles.workHoursIcon}>
            <img src={context.common.state.operating === true ? active : inactive} alt=""/>
        </div>
        <div
            className={styles.workHoursTitle}>{context.common.state.operating === true ? "Работаем сейчас" : "Закрыты до завтра"}</div>
        <div className={styles.workHoursSubtitle}>Пн-Вс с 11:00 до 22:30</div>
    </div>
}

const Zones = ({selected, onZoneSelect, onModeChange}) => {
    return <div className={styles.zones}>
        <div className={styles.zoneDots}>
            <div
                className={classNames(styles.zoneDot, styles.zoneDotGreen, selected === "green" ? styles.active : null)}
                onClick={() => onZoneSelect("green")}
            />
            <div
                className={classNames(styles.zoneDot, styles.zoneDotYellow, selected === "yellow" ? styles.active : null)}
                onClick={() => onZoneSelect("yellow")}
            />
            <div
                className={classNames(styles.zoneDot, styles.zoneDotOrange, selected === "orange" ? styles.active : null)}
                onClick={() => onZoneSelect("orange")}
            />
        </div>
        <div className={styles.zoneDetails}>
            <div className={styles.zoneDescription}>
                <div
                    className={classNames(styles.zoneDescriptionIcon, descriptions[selected].icon)}/>
                <div className={styles.zoneDescriptionTexts}>
                    <div
                        className={styles.zoneDescriptionName}>
                        {descriptions[selected].name}
                    </div>
                    {descriptions[selected].sections.map(el => {
                        return <div className={styles.zoneDescriptionItem}>
                            <div className={styles.zoneDescriptionTitle}>{el.title}</div>
                            <div className={styles.zoneDescriptionSubs}>
                                {el.subs.map(el => {
                                    return <div>{el}</div>
                                })}
                            </div>
                        </div>
                    })}
                </div>
            </div>
            <div className={styles.zonePickup} onClick={() => onModeChange("points")}>
                <div className={styles.zonePickupIcon}>
                    <img src={rest} alt=""/>
                </div>
                <div className={styles.zoneDescriptionTexts}>
                    <div className={styles.zoneDescriptionItem}>
                        <div className={styles.zoneDescriptionTitle}>точки самовывоза</div>
                        <div className={styles.zoneDescriptionSubs}>
                            <div>15% скидка при оплате наличными</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

Conditions.contextType = AppContext;
