import React, {useContext, useState} from 'react'
import {generatePath, useHistory} from "react-router-dom";

import img1 from '../../../static/images/banner/img1.png'
import img2 from '../../../static/images/banner/img2.png'
import img3 from '../../../static/images/banner/img3.png'

import {AppContext} from "../../../store/context";

import styles from './Banner.module.css'
import {publicRoutes} from "../../../routing";

export const Banner = () => {
    const {context} = useContext(AppContext);
    const [active, setActive] = useState(2);
    const history = useHistory();

    return <div className={styles.container}>
        <div className={styles.imagesSection}>
            <div className={styles.overlayTop}/>
            <div className={styles.images}>
                <div className={`${styles.image} ${active === 0 ? styles.active : ''}`}
                     style={{backgroundImage: `url(${img1})`}}
                     onClick={() => {
                         setActive(0)
                     }}/>
                <div className={`${styles.image} ${active === 1 ? styles.active : ''}`}
                     style={{backgroundImage: `url(${img2})`}}
                     onClick={() => {
                         setActive(1)
                     }}/>
                <div className={`${styles.image} ${active === 2 ? styles.active : ''}`}
                     style={{backgroundImage: `url(${img3})`}}
                     onClick={() => {
                         setActive(2)
                     }}/>
            </div>
            <div className={styles.overlayBot1}/>
            <div className={styles.overlayBot2}/>
        </div>
        <div className={styles.textSection}>
            <div className={styles.preHeading}>한국 문화 레스토랑</div>
            <h1 className={styles.heading}>прикоснитесь к корейской культуре</h1>
            <div className={styles.text}><span>Самый простой способ прикоснуться к другой культуре – ощутить ее национальный вкус</span>
            </div>
            <div className={styles.list}>
                <div className={styles.listItem} onClick={() => {
                    history.push(generatePath(publicRoutes.restaurants.path))
                }}>
                    <div className={styles.listItemBullet}>주소</div>
                    <div className={styles.listItemText}>РЕСТОРАНЫ</div>
                </div>
                <div className={styles.listItem} onClick={() => {
                    history.push(generatePath(publicRoutes.delivery.path))
                }}>
                    <div className={styles.listItemBullet}>배달</div>
                    <div className={styles.listItemText}>ДОСТАВКА</div>
                </div>
                <div className={styles.listItem}>
                    <div className={styles.listItemBullet}>식당</div>
                    <a href={"/menu"} target={"_blank"} className={styles.listItemText} rel="noopener noreferrer">
                        меню ресторана
                    </a>
                </div>
                <div className={styles.listItem}>
                    <div className={styles.listItemBullet}>그릴</div>
                    <a href={"/koreana-grillmenu"} target={"_blank"} className={styles.listItemText} rel="noopener noreferrer">
                        гриль меню
                    </a>
                </div>
                <div className={`${styles.listItem} ${styles.listItemSpace}`}>
                    <div className={styles.listItemBullet}>바</div>
                    <a href={"/koreana-barmenu"} target={"_blank"} className={styles.listItemText} rel="noopener noreferrer">
                        бар меню
                    </a>
                </div>
                <div className={styles.listItem}>
                    <button
                        type="button"
                        className={`${styles.listItemText} ${styles.listItemTextIconGift}`}
                        onClick={() => {
                            context.modals.giftCertificate.dispatch({type: 'open'});
                        }}
                     >
                        Подарочный сертификат
                    </button>
                </div>
            </div>
        </div>
    </div>
}
